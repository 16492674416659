const Investment = () => {
    return(
        <section className="investment" id="investment">
            <div className="investment__title">
                <h1 className="title">Por que eu deveria investir em imóveis nos Estados Unidos?</h1>
                <p className="description">Diferente do Brasil os EUA tem uma economia muito estável e sabendo que o dólar hoje vale 5x mais que o real Investir em imóveis na terra do Tio Sam possibilita você a realizar:</p>
            </div>
            <div className="investment__icons">
                <p className="investment__icons-1">A viagem dos sonhos</p>
                <p className="investment__icons-2">Adquirir outros imóveis</p>
                <p className="investment__icons-3">Renda passiva</p>
                <p className="investment__icons-4">Criar múltiplas rendas</p>
                <p className="investment__icons-5">Planejar os estudos dos filhos</p>
                <p className="investment__icons-6">Aposentadoria antecipada</p>
                <p className="investment__icons-7">Carro novo</p>
                <p className="investment__icons-8">Plano de saúde diferenciado</p>
            </div>
        </section>
    )
}

export default Investment;