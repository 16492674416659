import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia/SocialMedia";
import LogoImage from '../../assets/logo/Logo.png';

const Footer = () => {
    return(
        <footer className="footer__container">
            <SocialMedia />
            <div className="footer">
                <span className="footer__link"><Link target={"_blank"} title="Entre em contato" to="https://api.whatsapp.com/send?phone=16032335519">Entre em Contato</Link></span>
                <p className="footer__copyright">2023 © Todos os direitos reservados - RN International Consulting</p>
                <p className="footer__terms">Termos de Uso - Políticas de Privacidade</p>
            </div>
            <div>
                <img src={LogoImage} alt="Logo" />
            </div>
        </footer>
    )
}

export default Footer;