import  '../../sass/styles.scss';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/logo/Logo.png';

const Header = () => {
    return(
        <header className="header">
            {
                window.innerWidth > 1024 ?
                    <section className="header__container">
                        <div className='header__container-item'>
                            <span className='logo'><a title="RN International Consulting" href="#banner"><img className='logo__image' src={logoImage} alt="RN International Consulting" /></a></span>
                            <nav className='nav'>
                                <ul className='nav__list'>
                                    <li className='nav__list-link-desktop'><a title="Comece por aqui" href="#info">Comece por aqui</a></li>
                                    <li className='nav__list-link-desktop'><a title="Por que investir?" href="#investment">Por que investir?</a></li>
                                    <li className='nav__list-link-desktop'><a title="Como funciona" href="#material">Como funciona</a></li>
                                    <li className='nav__list-link-desktop'><a title="Imóveis" href="#dynamic">Imóveis</a></li>
                                    <li className='nav__list-link-desktop'><a title="Sobre nós" href="#about">Sobre nós</a></li>
                                    <li className='nav__list-link-desktop'><a title="Perguntas frequentes" href="#questions">Perguntas frequentes</a></li>
                                </ul>
                            </nav>
                            <span className='contact'><Link target={"_blank"} title="Entre em contato" to="https://api.whatsapp.com/send?phone=16032335519">Entre em contato</Link></span>
                        </div>
                    </section>
                : 
                    <section className="header__container">
                        <div className='header__container-item'>
                            <span className='logo'><a title="RN International Consulting" href="#banner"><img className='logo__image' src={logoImage} alt="RN International Consulting" /></a></span>
                            <span className='contact'><Link target={"_blank"} title="Entre em contato" to="https://api.whatsapp.com/send?phone=16032335519">Entre em contato</Link></span>
                        </div>
                    </section>
            }
        </header>
    )
}

export default Header;