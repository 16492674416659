import playIcon from '../../assets/icons/play-video.svg';
import { Link } from 'react-router-dom';


const Banner = () => {
    return (
        <section className="banner" id="banner">
            <aside className="banner__left">
                <div className="banner__content-1">
                        <h1 className="title">A <span className="span-title">oportunidade da sua aposentadoria</span> chegou mais cedo do que você esperava!</h1>
                        <p className="description">Descubra como investir hoje em propriedades nos Estados Unidos que podem gerar rendimentos líquidos anuais de até 42 mil reais, além da valorização do imóvel.</p>
                        <span className="banner_link"><Link target={"_blank"} title="Entre em contato" to="https://api.whatsapp.com/send?phone=16032335519">Entre em Contato</Link></span>
                </div>
                <div className="banner__content-2">
                    <img className="banner__content-2-image" src={playIcon} alt="Clique para ver o vídeo" />
                </div>
            </aside>
        </section>
    )
}

export default Banner;