import { useState } from 'react';

const questions = [
    {
        title: 'Moro no Brasil, posso financiar um imóvel nos EUA?',
        description: 'Sim, você só precisa ter de 30% a 35% de entrada.',
        label: 'label1'
    },
    {
        title: 'Eu preciso ir até os Estados Unidos para fazer a aquisição do imóvel?',
        description: 'Não, a RN International Consulting cuida de tudo pra você através de uma procuração',
        label: 'label2'
    },
    {
        title: 'Eu terei um custo mensal para a administração do imóvel para aluguel?',
        description: 'Sim, mas entregamos o imóvel já alugado para você e aos cuidados da empresa que irá administrar essa parte.',
        label: 'label3'
    },
    {
        title: 'Em quanto tempo consigo quitar esse financiamento?',
        description: 'Os financiamentos acima de $180.000 são realizados em até 30 anos, mas com a ajuda da RN podemos usar os próprios ganhos e investir em fundos que te ajudará com o retorno a pagar este imóvel em 10 anos',
        label: 'label4'
    },
    {
        title: 'Se eu preferir fazer o pagamento integral do imóvel, recebo a escritura em quanto tempo?',
        description: 'No ato da aquisição',
        label: 'label5'
    },
    {
        title: 'Tenho um imóvel no Brasil que gostaria de usar o valor dele para adquirir um em Orlando, como faço?',
        description: 'Neste caso específico temos uma equipe parceira no Brasil que te auxiliará na venda do seu imóvel e podendo assim investir aqui na América.',
        label: 'label6'
    }
]

const Questions = () => {
    const [ isLabel, setLabel ] = useState('');

    return (
        <section className="questions" id="questions">
            <div className="questions__col-left"><h3 className="title">Perguntas frequentes</h3></div>
            <div className="questions__col-right">
                {questions.map((question,indice) => (
                    <div key={indice} className="question-item">
                        <h1 className={`ask ${isLabel === question.label ? 'open' : 'close'}`} onClick={() => {
                            if(question.label === questions[indice].label){
                                setLabel(question.label);
                            }
                        }}>{question.title}</h1>
                        {
                            isLabel === question.label &&
                                <p className="answer">{question.description}</p>
                        }
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Questions;