import CookieConsent from "react-cookie-consent";

const CookieAlert = () => {
    return(
        <CookieConsent
            location="bottom"
            buttonText="Aceitar"
        >
            Este site utiliza cookies, armazenando dados temporariamente, para otimizar a experiência do usuário.
        </CookieConsent>
    )
}

export default CookieAlert;