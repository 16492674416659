import { Helmet } from 'react-helmet-async';

const Seo = () => {
    return(
        <Helmet>
            <title>RN International Consulting</title>
            <link rel="canonical" href="https://rninternational.us/" />
            <meta name="description" content="Descubra como investir em propriedades nos Estados Unidos e gerar rendimentos líquidos anuais de até 42 mil reais." />
        </Helmet>
    )
}

export default Seo;