const boxInfos = [
    {
        title: 'Business',
        label: 'Será realizado primeiramente a abertura das contas pessoal e business na contabilidade.'
    },
    {
        title: 'Banco',
        label: 'Faremos a abertura de uma conta bancária aqui na América.'
    },
    {
        title: 'Mortgage',
        label: 'Neste estágio do processo escolhemos o banco com a melhor taxa para a realização do financiamento referente ao saldo restante do imóvel.'
    },
    {
        title: 'Corretor',
        label: 'Depois da parte financeira estar ok teremos os corretores a sua disposição para a escolha do seu imóvel de preferência.'
    },
    {
        title: 'Advogada',
        label: 'Temos um profissional jurídico para realizar o seu contrato de compra e venda a segurando e protegendo o seu novo bem.'
    },
    {
        title: 'Seguro',
        label: 'Neste passo nossa equipe trata de encontrar o melhor seguro e proteção para o seu mais novo bem adquirido.'
    },
    {
        title: 'Adm. da locação',
        label: 'Seu imóvel já estará locado em poucas semanas garantindo a você o retorno imediato sobre o investimento.'
    }
]

const Material = () => {
    return(
        <section className="material" id="material">
            <div className="material__container">
                <div className="material__left-col">
                    <h2 className="title">Como funciona o processo para a aquisição do seu imóvel para investimento</h2>
                </div>
                <div className="material__right-col">
                    {boxInfos.map((item,index)=> (
                        <div key={index} className="material__item">
                            <h2 className="title">{item.title}</h2>
                            <p className="label">{item.label}</p>
                        </div>
                    ))} 
                </div>
            </div>
        </section>
    )
}

export default Material;