// import { Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header/header.component";
import Banner from "./components/Banner/Banner";
import Info from "./components/Info/Info";
import Investment from "./components/Investiment/Investiment";
import Material from "./components/Material/Material";
import About from "./components/About/About";
import Questions from "./components/Questions/Questions";
import Footer from "./components/Footer/Footer";
import Seo from "./components/Seo/Seo";
import DynamicSlider from "./components/DynamicSlider/DynamicSlider";
import CookieAlert from "./components/CookieAlert/CookieAlert";

const App = () => {
  return(
    <>
      <HelmetProvider>
        <Seo />
        <Header />
        <Banner />
        <Info />
        <Investment />
        <Material />
        <DynamicSlider />
        <About />
        <Questions />
        <Footer />
        <CookieAlert />
      </HelmetProvider>
    </>
  )
}

export default App;