import React, { useEffect , useState } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import propertie1 from '../../assets/images/properties/casa-orlando-florida-149.webp';
import propertie2 from '../../assets/images/properties/casa-orlando-florida-215.webp';
import propertie3 from '../../assets/images/properties/casa-kissimmee-289.webp';
import propertie4 from '../../assets/images/properties/casa-kissimmee-165.webp';
import propertie5 from '../../assets/images/properties/casa-davenport-375.webp';

const properties = [
    { 
        url: propertie1,
        alt: 'Casa em Orlando Florida por $149,000',
        local: 'Orlando Florida',
        price: '$149,000',
        detail1: 'Entrada (20%): $29,800',
        detail2: 'Wipe down all mirrors and glass fixtures',
        detail3: 'Clean all floor surfaces',
        detail4: 'Você poderá alugar esta casa por: $1500,00'
    },
    { 
        url: propertie2,
        alt: 'Casa em Orlando Florida por $215,000',
        local: 'Orlando Florida',
        price: '$215,000',
        detail1: 'Dust all accessible surfaces',
        detail2: 'Wipe down all mirrors and glass fixtures',
        detail3: 'Clean all floor surfaces',
        detail4: 'Take out garbage and recycling'
    },
    { 
        url: propertie3,
        alt: 'Casa em Kissimmee por $289,000',
        local: 'Kissimmee',
        price: '$289,000',
        detail1: 'Dust all accessible surfaces',
        detail2: 'Wipe down all mirrors and glass fixtures',
        detail3: 'Clean all floor surfaces',
        detail4: 'Take out garbage and recycling'
    },
    { 
        url: propertie4,
        alt: 'Casa em Kissimmee por $165,000',
        local: 'Kissimmee',
        price: '$165,000',
        detail1: 'Dust all accessible surfaces',
        detail2: 'Wipe down all mirrors and glass fixtures',
        detail3: 'Clean all floor surfaces',
        detail4: 'Take out garbage and recycling'
    },
    { 
        url: propertie5,
        alt: 'Casa em Davenport por $375,990',
        local: 'Davenport',
        price: '$375,990',
        detail1: 'Dust all accessible surfaces',
        detail2: 'Wipe down all mirrors and glass fixtures',
        detail3: 'Clean all floor surfaces',
        detail4: 'Take out garbage and recycling'
    }
]

const settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode: true
            }
        }
    ]
};

const settingsDesktop = {
    // dots: true,
    arrows: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '450px'
}


const DynamicSlider = () => {
    const [ counter, setCounter ] = useState(0);

    useEffect(() => {
        let counterNumber = 0;
        let nextArrow = document.querySelector(".dynamic .slick-slider .slick-next");
        let prevArrow = document.querySelector(".dynamic .slick-slider .slick-prev");
            
        nextArrow.addEventListener('click', () => {
            if(counterNumber <= properties.length){
                setCounter(++counterNumber);
            }

            if(counterNumber === properties.length){
                nextArrow.setAttribute('disabled','disabled');
            }

            prevArrow.removeAttribute('disabled');
        })

        prevArrow.addEventListener('click', () => {
            if(counterNumber >= 0){
                setCounter(--counterNumber);
            }

            if(counterNumber === 0){
                prevArrow.setAttribute('disabled','disabled');
            }

            nextArrow.removeAttribute('disabled');
        })
    },[])

    return(
        <section className="dynamic" id="dynamic">
            <div className="dynamic__container">
                <h2 className="title">Confira alguns imóveis</h2>
                <p className="content">Conheça agora alguns imóveis que na Flórida e que hoje mesmo </p>
                <p className="content">já podem ser seu.</p>
            </div>
            {
                window.innerWidth > 1024 ? 
                    <Slider {...settingsDesktop}>
                        {
                            properties.map((propertie,index) => (
                                <div key={index} className="dynamic__active">
                                    <div className="dynamic__infos">
                                        <div className="dynamic__infos-header">
                                            <h3>{propertie.local}</h3>
                                            <h3>{propertie.price}</h3>
                                        </div>
                                        <div className="dynamic__infos-footer">
                                            <ul>
                                                <li>{propertie.detail1}</li>
                                                <li>{propertie.detail2}</li>
                                                <li>{propertie.detail3}</li>
                                                <li>{propertie.detail4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src={propertie.url} alt={propertie.alt} />
                                </div>
                            ))
                        }
                    </Slider>
                : 
                    <Slider {...settingsMobile}>
                        {
                            properties.map((propertie,index) => (
                                <div key={index} className="dynamic__active">
                                    <div className="dynamic__infos">
                                        <div className="dynamic__infos-header">
                                            <h3>{propertie.local}</h3>
                                            <h3>{propertie.price}</h3>
                                        </div>
                                        <div className="dynamic__infos-footer">
                                            <ul>
                                                <li>{propertie.detail1}</li>
                                                <li>{propertie.detail2}</li>
                                                <li>{propertie.detail3}</li>
                                                <li>{propertie.detail4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src={propertie.url} alt={propertie.alt} />
                                </div>
                            ))
                        }
                    </Slider>
            }
            
            <>
                {
                    // properties.map()
                    // properties[0].url.split('/')[3].split('-')[1].split('.')[0]
                }
                <div className="dynamic__hr-line">
                    <div style={{width: `${(counter !== 100 ? counter * (100 / properties.length) : 0)}%`}} className="progressBar"></div>
                </div>
                <span className="dynamic__link"><Link target={"_blank"} title="Entre em contato" to="https://api.whatsapp.com/send?phone=16032335519">Quero Investir</Link></span>
            </>
        </section>
    )
}

export default DynamicSlider;

