import image1 from '../../assets/images/disney-1.webp'
import image2 from '../../assets/images/disney-2.webp'
import image3 from '../../assets/images/disney-3.webp'
import image4 from '../../assets/images/disney-4.webp'

const Info = () => {
    return (
        <main className='info' id="info">
            <section className='info__container'>
                <div className='info__content-1'>
                    <img src={image1} alt="Disney 1" />
                    <img src={image2} alt="Disney 2" />
                    <img className='info__image-3' src={image3} alt="Disney 3" />
                    <img src={image4} alt="Disney 4" />
                </div>
                <div className='info__content-2'>
                    <h2 className='title'>Investir nos Estados Unidos parece algo impossível, não é mesmo?</h2>
                    <p className='description'>Esse é o segredo que muitos milionários não contam e que estamos dispostos a revelar! Qualquer pessoa que tenha de 30% a 35% de entrada referente ao valor do imóvel e os requisitos que atendam a forma de financiamento podem ter seu imóvel aqui na América. Os imóveis estão localizados a poucos minutos de distância da Disney e Universal.</p>
                </div>
            </section>
        </main>
    )
}

export default Info;