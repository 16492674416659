import { Link } from "react-router-dom";

const SocialMedia = () => {
    return(
        <section className="social">
            <Link target={"_blank"} title="Acesse o Instagram da RN International" to="https://instagram.com/rodrigonascimentoeua?igshid=YmMyMTA2M2Y="><i className="instagram"></i></Link>
            {/* <Link target={"_blank"} title="Acesse o LinkedIn da RN International" to="https://www.linkedin.com/in/rodrigo-nascimento-7bb701198"><i className="linkedin"></i></Link> */}
            <Link target={"_blank"} title="Acesse o WhatsApp da RN International" to="https://api.whatsapp.com/send?phone=16032335519"><i className="whatsapp"></i></Link>
        </section>
    )
}

export default SocialMedia;