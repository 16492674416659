import imageAbout from '../../assets/images/about.webp';

const About = () => {
    return(
        <section className="about" id="about">
            <div className="about__container">
                <div className="about__left-col">
                    <img src={imageAbout} alt="Sobre a empresa" />
                </div>
                <div className="about__right-col">
                    <h2 className='title'>Sobre Nós</h2>
                    <p className='description'>A RN International Consulting tem ajudado centenas de investidores internacionais e brasileiros que vivem nos Estados Unidos a adquirir seu imóvel para morar ou investir, trazendo os melhores profissionais para auxiliar no processo desta conquista.</p> 
                    <p className='description'>Somos uma consultoria que ao longo dos anos tem se destacado no mercado realizando o sonho da casa própria e fazendo com que pessoas aprendam a investir em algo que possa dar retorno rápido e constante.
                    Afinal, os imóveis são até hoje o bem que mais valoriza todos os dias no mundo todo. </p>
                    <p className='description'>Queremos fazer parte desta realização tão importante para você.</p>
                </div>
            </div>
        </section>
    )
}

export default About;